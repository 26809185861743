.demo-main {
}

.face-back-div {
  display: flex !important;
  flex-direction: column;
  justify-content: space-evenly !important;
  align-items: center !important;
  min-height: 100vh !important;
  display: none;
}

.demo-products {
  justify-content: center;
  align-items: center;
}
.demo-products-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.demo-prdoucts-body-modal {
  background-color: #827b75;
  border: none !important;
}
.demosimg {
  aspect-ratio: 1/1;
  width: 100%;
}

.Choose-Product-Div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Choose-Product {
  text-align: center;
  color: white;
  font-weight: bold;
  font-size: 2rem;
  color: #9b6644;
  font-family: "Poppins", sans-serif;
}

.demosproductsimgs {
  width: 100%;
}

/* File upload Code to get rid of all that stuff */
/* File upload Code to get rid of all that stuff */
/* File upload Code to get rid of all that stuff */
label.sc-aXZVg > svg {
  display: none !important;
}

.sc-hLBbgP,
.dtlKip > span {
  display: none !important;
}

.jWkLDY,
.sc-fqkvVR,
.cKeOpf > span {
  display: none !important;
}

/* File upload Code to get rid of all that stuff */
/* File upload Code to get rid of all that stuff */
/* File upload Code to get rid of all that stuff */

.guZdik,
.dtlKip {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-width: 300px !important;
  max-width: 508px;
  height: 15rem !important;
  border: 2px dashed #9b6644 !important;
  padding: 8px 16px 8px 8px;
  border-radius: 5px;
  cursor: pointer;
  -webkit-box-flex: 0;
  flex-grow: 0;
}

.File-Uploader-main-Div {
  position: relative !important;
  cursor: pointer !important;
}

.Upload-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 4rem;
  color: #9b6644;
}

.upload-image-text-div {
  font-family: "Poppins", sans-serif;

  padding-top: 3.5rem;
  font-size: 1.5rem;
  color: #9b6644;
  text-align: center;
}

.Brand-Toggle-Button-Outside-of-Blur {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #503323;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.Brand-Toggle-Button-Outside-of-Blur-For-hair {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #503323;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.demoscard:hover {
  transform: scale(1.04);
  transition: 1s;
}

.demo-products-modal-main {
}

.choose_brand_h3 {
  font-family: "Noto Serif Display", serif;
}

.upload_text {
  font-family: "Noto Serif Display", serif;
}

.try-on-btn {
  font-family: "Noto Serif Display", serif;
  border-radius: 10px !important;
}

.brands {
  font-family: "Noto Serif Display", serif;
}

.colors-map-div {
  font-family: "Poppins", sans-serif;
  color: #9d6948;
}

.demo-products-names {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s ease;
  /* font-weight: bold; */
  font-size: 2.5rem !important;
  font-family: "Noto Serif Display", serif;
}

.borderAdded {
  border: 1px solid #9d6948 !important;
}

.borderRemoved {
  border: none;
}

.vertical-line {
  border-left: 1px solid #000;

  height: 100px;

  width: 100px;
}

.face-btns {
  padding: 1rem !important;
}

.demo-card-div {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.demoProductsOpenedCard {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.demoProductsOpenedCard img {
  aspect-ratio: 1/1;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease;
}

.demoProductsOpenedCard:hover {
  transform: scale(1.03);
  transition: 1s;
}

.demoproductimgs {
  /* padding: 0 !important; */
  aspect-ratio: 1.2 !important;
}

.demo-text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s ease;
  font-weight: bold;
  font-size: 2rem;
  font-family: "Poppins", sans-serif;
}

.getTheApp {
  color: white;
  text-decoration: none;
}

.dropdownItems,
.choose-brand {
  font-family: "Poppins", sans-serif !important;
  padding: 1rem !important;
}

.choose-brand {
  background-color: #ddb892 !important;
  padding: 0.5rem;
  border-radius: 10px;
  margin: 0.5rem;
  border: none !important;
  font-size: 1.2rem;
  color: #503323 !important;
}

.dropdownItems:hover {
  background-color: #e7cdb3 !important;
}

.dropdDownMenuItems {
  background-color: #ddb892 !important;
  color: #653d2a !important;
  height: 40vh !important;
  overflow-y: scroll !important;
}

.demo-imagehairstyles-div {
  margin-top: 1rem;
  width: 100%;
  display: flex;
  gap: 1rem;
  overflow: scroll;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.demo-imagehairstyles {
  width: 10rem;
  height: 10rem;
  border-radius: 2rem;
}

.modal-body-hairstyles {
  min-width: 100% !important;
  background-color: #eee1d4 !important;
  min-height: 70vh !important;
  justify-content: center !important;
  position: relative !important;
  padding: 0 !important;
  border: none !important;
}

.modal-content {
  border: none !important;
}

.modal-inner-div {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.modal-inner-div-blur {
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-direction: column;
  position: relative;
  filter: blur(10px);
}

.modal-inner-inner-div {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.virtual-loader {
  width: 100%;
  color: white;
  position: absolute;
  top: 0%;
  height: 100%;
  font-size: 2rem;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.SelectHairStyle {
  color: #4f3323;
  background-color: #ddb892;
  padding: 1rem;
  border-radius: 10px;
  margin: 0.5rem;
  border: none;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

.click-any-where-div {
  bottom: 10%;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
  text-align: center;
  width: 100%;
}

.isloadingDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 250px;
  height: 180px;
  background-color: #28160c;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 20px;
  border: 5px solid white;
  z-index: 9999;
  /* #432E20 */
}

.received-data-div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: #ddb892;
}

.received-data-heading-div {
  display: flex;
  justify-content: space-between;

  align-items: center;
  width: 100%;
  margin-top: 2.5rem;
}

.try-with-diff-shade-text {
  font-family: "Poppins", sans-serif;
}

.foundation-virtual-differetn-shades-div {
  font-family: "Poppins", sans-serif;
}

.foundationvirtualcompanyheading {
  font-family: "Noto Serif Display", serif;
}

.received-data-heading {
  text-align: center;
  color: #432e20;
  padding: 1rem;
  font-family: "Noto Serif Display", serif;
  font-weight: bold;
}

.how-well {
  color: white;
}

.api-items-div-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.buy-virtual-div {
  display: flex;
}

.api-items-container {
  background-color: #e4c4c7;
  margin: 0.3rem;
  padding: 0.5rem;
  border-radius: 20px;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2) !important;
  margin: 0.4rem;
  overflow: auto;
  white-space: nowrap;
}

.rate-us-btn {
  background-color: #ae4e58 !important;
  border: none !important;
  padding: 0.5rem;
  color: white;
  border-radius: 10px;
  font-size: 1.5rem;
  margin: 1rem;
  font-family: "Noto Serif Display", serif;
}

.company-name-heading-div {
  font-family: "Noto Serif Display", serif;
}

.api-items-container {
  font-family: "Poppins", sans-serif;
}

.rate-us-btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.api-goodies-div {
  /* font-family: 'Montserrat', sans-serif !important; */
}

.face-btns {
  padding: 1rem !important;
}

.product-name {
  font-weight: bold;
  text-align: center;
  font-weight: bold;
  border-radius: 10px;
  color: #9b0330 !important;
  padding: 0.5rem;
  padding-right: 0rem;
}

.company-name-heading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.company-name-heading {
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  font-weight: bold;
  border: 1px solid black;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2) !important;
  background-color: #432e20;
  color: white;
  margin: 1rem;
}

.api-details-div {
  background-color: #ddb892;
  border: 5px solid white;
  margin-top: 2rem;
  padding: 1rem;
  border-radius: 10px;
  margin: 0.2rem;
}

.images-div {
  display: flex;
  justify-content: center;
}

.api-images {
  width: 8rem;
  height: 5rem;
  border-radius: 10px;
}

.shade-price-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-weight: bold;
}

.api-company-price-div {
  display: flex;
  justify-content: space-between;
}

.api-company,
.api-price {
  font-size: 1rem;
  color: white;
  font-weight: bold;
}

.api-shade {
  font-size: 1rem;
  color: black;
  font-weight: bold;
}

.api-btns-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.api-btns {
  align-self: center;
  background-color: #432e20;
  /* padding: 0rem !important; */
  border-radius: 10px;
  width: 100%;
  margin-top: 10;
  color: white;
  margin: 0.1rem;
  font-weight: bold;
  border: none;
  border: 1px solid white;
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2) !important;
  padding: 0.5rem !important;
}

.buy-btn {
  background-color: #ae4e58 !important;
}

.virtual-btn {
  background-color: #ae4e58 !important;
}

.video-btn {
  background-color: #ae4e58 !important;
}

.go-back-btn {
  background-color: #28160c !important;
  border: none !important;
  margin: 0.5rem;
  position: absolute;
  z-index: 999;
  right: 1rem;
  top: 1rem;
  padding: 0.5rem !important;
}

.sign-out-btn {
  background-color: #28160c !important;
  border: none !important;
  margin: 0.5rem;
  position: absolute;
  z-index: 999;
  left: 1rem;
  top: 1rem;
  padding: 0.5rem !important;
  font-family: "Noto Serif", serif !important;
}

.Login-btn-btn {
  background-color: #dcb06f !important;
  border: none !important;
}

.demo-img-div {
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif !important;
  margin: 0.5rem;
}

.demo-img {
  height: 30vh !important;
  border-radius: 20px;
}

.demo-img-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-15%, -50%);
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.5s ease;
  font-weight: bold;
  border-radius: 20px;
}

.demo-caption-big,
.demo-caption-small {
  font-weight: bold;
  font-family: "Noto Serif Display", serif;
}

/* SignUp Modal */

.SignUp-Btns-div {
  background-color: #eee1d4 !important;
}

form .signup-form-div {
  color: #a8724b !important;
  font-family: "Montserrat", sans-serif !important;
}

.login-form-div {
  color: black !important;
  font-family: "Montserrat", sans-serif !important;
}

.sign-up-text {
  color: #a8724b !important;
  font-weight: bold;
}

.formlabel {
}

.signup-submit-btn {
  background-color: #a8724b !important;
  border: none !important;
}

.login-submit-btn {
  background-color: #dcb06f !important;
  border: none !important;
}

.gmail-signup-btn {
  background-color: white !important;
  border: none !important;
  border: 1px solid #a8724b !important;
  color: #a8724b !important;
}

.gmail-login-btn {
  background-color: white !important;
  border: none !important;
  border: 1px solid #dcb06f !important;
  color: #dcb06f !important;
}

.already-text {
  color: #dcb06f;
}

.logo-sign-text-div {
}

.logo-login-text-div {
}

.logo-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-on-signUp {
  margin-top: 3rem;
  width: 5rem;
}

.SignUp-Btns-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.signup-modal-header {
  border: none !important;
  position: absolute;
  z-index: 999;
}

.google-img {
  width: 1.4rem;
}

.signup-modal-body {
  padding: 0rem !important;
  font-weight: bold;
}

.submit-btn-div {
  margin-bottom: 0rem !important;
}

.submit-btn-div,
.gmail-signup-btn-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.sign-up-text {
  text-align: center;
}

.or-text {
  padding: 0rem;
  margin: 1rem;
  text-align: center;
}

@media only screen and (max-width: 300px) {
  .demo-caption-big {
    font-size: 1rem;
  }

  .demo-caption-small {
    font-size: 0.5rem;
  }
}

@media only screen and (max-width: 700px) {
  .shade,
  .buy-btn,
  .video-btn,
  .video-btn,
  .api-price {
    font-size: 0.8rem;
  }

  .rate-us-btn {
    font-size: 1rem;
  }

  .received-data-heading-div {
    margin-top: 2.5rem;
  }
}

.modal-img {
  margin-top: 1rem;
}

.img-text {
  font-family: "Noto Serif Display", serif;
  font-weight: bold !important;
  bottom: 5rem !important;
}

.sc-bcXHqe,
.lkjDVC {
  border: 2px dashed #bb9b80 !important;
  padding: 6rem !important;
  min-width: 300px !important;
  height: 350px !important;
}

.sc-hLBbgP,
.bXgVCj {
  color: #bb9b80 !important;
  text-align: center !important;
  font-size: 1.5rem !important;
  font-family: "Noto Serif Display", serif;
  text-decoration: none !important;
}

.bXgVCj span {
  text-decoration: none !important;
}

.file-uploader-div {
  margin-top: 1rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sc-bcXHqe,
.lkjDVC svg {
  display: none;
}

.file-types {
  display: none !important;
}

.upload-Icon {
  position: absolute;
  font-size: 2rem;
  color: #bb9b80 !important;
  align-self: center;
  top: 0rem;
}

.upload-instructions-div {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  color: #ae4e58;
  padding: 1rem;
}

.founConSkin-try-demo-btn {
  background-color: #a40036 !important;
  font-size: 1.6rem !important;
  border: 1px solid #a40036 !important;
  font-family: "Noto Serif Display", serif;
  color: white;
  border-radius: 10px;
  font-weight: bold;
  margin: 0.8rem !important;
}

.founConSkin-try-demo-btn-btn:hover {
  scale: 1.1;
}

.img-text {
  font-family: "Noto Serif Display", serif;
  font-weight: bold !important;
  bottom: 5rem !important;
}

@media only screen and (max-width: 990px) {
  .img-text {
    font-family: "Noto Serif Display", serif;
    font-weight: bold !important;
    bottom: 1rem !important;
    font-size: 0.5rem;
  }
}

@media (max-width: 768px) {
  .demo-text-overlay {
    height: 100%;
    width: 100%;
  }

  .demoProductsOpenedCard img {
    width: 100%;
    transition: transform 0.5s ease;
    /* margin: 0.2rem; */
  }

  .demo-products-modal-main {
    margin-top: unset;
  }

  .demo-products-names {
    font-size: 1.5rem !important;
  }

  .demoProductsOpenedCard:hover {
    transform: unset;
    transition: unset;
  }

  .foundation-virtual-differetn-shades-div {
    max-width: 120% !important;
  }

  .upload-instructions-div {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ae4e58;
  }
}

@media (max-width: 600px) {
  .demo-products {
    margin-top: 5rem;
    height: 50vh;
  }

  .demo-products-names {
    font-size: 1.3rem !important;
  }

  .demo-text-overlay {
    font-size: 1.3rem !important;
  }

  .Choose-Product {
    font-size: 1rem;
  }

  .click-any-where-div {
    bottom: 0%;
  }

  .virtual-loader {
    font-size: 1.3rem;
  }

  .face-back-div div:nth-child(1) img {
    width: 100%;
  }

  .demo-card-div {
    display: inline-grid;
    grid-template-columns: unset;
  }
}

@media (min-width: 1024px) {
}

@media (min-width: 1440px) {
}

@media (min-width: 2560px) {
}
