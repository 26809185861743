.robo-main-div {
  font-family: "Syne", sans-serif !important;
  display: flex;
  justify-content: center;
  margin-top: 8rem;
}

.window {
  width: 100vw;
  /* min-height: 50vh; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.robomua-products-nav {
  background: #fdfdfd;
  padding: 5px 5px 0;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid #eeeeee;
  height: 5rem;
}

.tabs {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  width: 100%;
}

.products-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
}

.robomua-products-nav ul,
.robomua-products-nav li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: 500;
  font-size: 14px;

  height: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.robomua-products-nav ul {
  display: flex;
  width: 100%;
}

.robomua-products-nav li {
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  width: 100%;
  padding: 10px 15px;
  position: relative;
  background: white;
  cursor: pointer;
  height: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-width: 0;
  position: relative;
  user-select: none;
}

.underline {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--accent);
}

.robomua-products-nav li.selected {
  background: #eee1d4;
  padding: 2rem;
  color: #a40036;
}

.robomua-products-nav li,
.robomua-products-nav li button {
  width: 20px;
  height: 20px;
  border: 0;
  background: #fff;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke: #000;
  margin-left: 10px;
  cursor: pointer;
  flex-shrink: 0;
  color: #6f4b33;
}

.icon-label-div {
  font-size: 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.visit-robomua {
  padding: 1rem;
}

/* Hero */
/* Hero */
/* Hero */
/* Hero */

.hero-main-text {
  font-size: 3.5vw;
  font-family: "Noto Serif Display", serif;
  color: #6f4b33;
  font-weight: bold;
}

.img-video-div {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-div {
  border-radius: 35px;
}

.phone-video-hero {
  width: 20rem;
  z-index: -1;
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08);
}

.iphone-frame-hero {
  /* position: absolute; */
  width: 15rem;
}

.hero-main {
}

.main-app-display {
  box-shadow: 0 0 1em rgba(0, 0, 0, 0.2) !important;
  width: 18rem;
}

.download-flyer {
  width: 8rem;
  height: 2.5rem;
  margin: 0.5rem;
}

.download-btns-div {
  display: flex;
  font-family: "Poppins", sans-serif;
}

.download-btns-div div:hover {
  scale: 1.1;
}

@media only screen and (max-width: 800px) {
  .hero-main-text {
    font-size: 2.5rem;
  }

  .hero-main {
    gap: 2rem;
  }

  .download-btns-div {
    display: flex;
    align-items: center;
    align-content: center;
  }
}

/* @media only screen and (max-width: 350px) {
  .download-btns-div {
    flex-direction: column;
  }
} */

/* chrome */
/* chrome */
/* chrome */
/* chrome */
/* chrome */

.download-chrome-btn {
  background-color: #a40036;
  text-decoration: none;
  padding: 0.8rem;
  border-radius: 10px;
  font-weight: bold;
  text-align: center;
  color: white;
  border: none;
}

.get-ready-text {
  text-align: center;
}

.chrome_text {
}

.download-chrome-btn:hover {
  scale: 1.1;
}

.chrome-browser-img {
  border-radius: 10px;
}

@media only screen and (max-width: 800px) {
  .chrome-heading {
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .icon-label-div {
    font-size: 1rem;
  }

  .img-video-div {
    padding: 2rem;
  }

  .roboproduct-hero {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .robomua-hero-main {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .download-btns-div {
    justify-content: center;
    margin: 1rem;
  }

  .chrome-browser-img {
    margin-top: 2rem;
  }

  .hero-main-text {
    font-size: 2rem;
    text-align: center;
  }

  .phone-video-hero {
    width: 10rem;
    z-index: -1;
  }
}
