:root {
  --height: calc(100vh - 50px);
  --width: 100%;
}

body,
html {
  padding: 0;
  align-items: start;
}

.carousel {
  margin-top: 5rem;
}

.carousel__list {
  display: flex;
  margin: 0;
  contain: layout;
  isolation: isolate;
  overflow: hidden;
}

.carousel__item {
  display: grid;
  position: relative;
  align-content: start;
  margin-right: 20px;
  padding: 0;
  flex: 1 1 21%;
  min-height: 100vh;
  contain: layout;
  isolation: isolate;
  overflow: hidden;
}

.carousel__item {
  transition: all 0.6s;
}

.data-active {
  flex-basis: var(--width);
  transition: all 0.6s ease;
}

.carousel__item img {
  position: absolute;
  width: var(--width);
  height: 100%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 2/3;
  object-fit: cover;
  z-index: -1;
}

.carousel__contents {
  position: absolute;
  top: 8%;
  color: white;
  display: flex;
  flex-direction: column;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(80vh - 10px);
  padding: 1rem;
  font-family: "Syne", sans-serif;
}

.carousel-main-text-div {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(80vh + 10px);
}

.carousel-main-text-div-active {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  min-height: calc(80vh + 10px);
  margin-left: 2rem !important;
}

.carousel-main-text-div div {
  transform: rotate(270deg);
  white-space: nowrap;
  color: white;
  font-size: clamp(2rem, 4rem, 4rem);
  font-family: "Syne", sans-serif;
  margin-right: 0rem !important;
  font-size: 3rem;
}

.carousel-main-text-div-active div {
  transform: rotate(270deg);
  white-space: nowrap;
  color: white;
  font-size: 3rem;
  font-family: "Syne", sans-serif;
}

.carousel-intro-text {
  width: 50%;
  padding: 1rem;
  font-family: "Syne", sans-serif;
  font-size: 2vw;
}

.button-group {
  padding: 1rem;
  font-family: "Syne", sans-serif;
  font-size: 1.5rem;
}

.button-group button {
  margin: 0.5rem;
  border: none;
  padding: 1rem;
  border-radius: 8px !important;
  color: white !important;
}

.button1 {
  background-color: #ae4e58;
}

.button2 {
  background-color: #9a002e !important;
}

.alyshashadebuttons {
  background-color: #9a002e !important;
}

.button-group button:hover {
  scale: 1.1;
}

@media screen and (max-width: 800px) {
  .carousel-intro-text {
    width: 100%;
    font-size: 4.5vw;
  }

  .carousel__contents {
    min-height: calc(50vh - 10px);
    padding: 1rem;
    top: 5%;
  }

  .button-group {
    font-size: 1rem !important;
    padding: 0rem !important;
  }

  .carousel-main-text-div {
    min-height: calc(80vh + 10px);
    margin-left: -0.5rem;
  }

  .carousel-main-text-div-active {
    margin-left: 2rem !important;
  }

  .carousel-main-text-div div {
    font-size: 2rem;
  }

  .carousel-main-text-div-active div {
    font-size: 2rem;
  }

  .button-group button:hover {
    scale: unset;
  }

  .data-active {
    flex-basis: var(--width);
    transition: all 0.6s ease;
  }
}

@media screen and (max-width: 600px) {
  html {
    overflow-x: hidden !important;
  }
  .carousel {
    height: calc(50vh - 10px);
    margin-top: 4rem !important;
  }

  .carousel__item img {
    position: absolute;
    width: var(--width);
    height: calc(50vh - 10px);
    left: 50%;
    top: 25%;
  }

  .carousel-intro-text {
    width: 100%;
    font-size: 3.5vw;
    margin-bottom: unset;
  }

  .carousel__contents {
    padding: 1rem;
    top: 0%;
    top: 0%;
    color: white;
    justify-content: center;
  }

  .button-group {
    padding: 0rem !important;
    display: flex !important;
  }

  .button-group button {
    padding: 0.5rem;
    font-size: 0.9rem;
  }

  .carousel__list {
    height: 50vh;
  }

  .carousel-main-text-div {
    width: 55%;
    justify-content: space-around;
    align-items: center;
    min-height: calc(54vh - 10px);
  }

  .carousel-main-text-div div {
    font-size: 1.2rem;
    margin-left: 0.5rem;
  }

  .carousel-main-text-div-active {
    margin-left: 2rem !important;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    min-height: calc(40vh + 10px);
    margin-left: 2rem !important;
  }

  .button-group button:hover {
    scale: unset;
  }

  .data-active {
    flex-basis: var(--width);
    transition: all 0.6s ease;
  }
}
