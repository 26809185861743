.yshade-text-image-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
  gap: 1.5rem;
  font-family: "Syne", sans-serif;
}

.yshade-text-image-div div {
  flex: 1 1 0;
}

.skinshadesImage {
  aspect-ratio: 1/1;
  width: 100vw;
}

.skinshade-texts {
  font-size: 3vw;
  color: #775039;
}

.data-set-texts-div {
  color: #775039;
  padding: 3rem;
}

.full-page-video-Shade {
  width: 90vw !important;
  margin-top: 5rem !important;
}

.full-page-video-Shade__content {
  width: 90vw !important;
}

.yshade-text-image-div div {
  flex: 1;
}

.contact-us-div-yshade {
  margin: 0.5rem;
  border: none;
  padding: 1rem;
  border-radius: 8px !important;
  color: white !important;
  background-color: #ae4e58;
  font-size: 1.3rem;
}

.contact-us-div-yshade:hover {
  scale: 1.1;
}

.banner-image {
  width: 100%;
  margin-bottom: 1rem;
}

@media (max-width: 600px) {
  .yshade-text-image-div {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    gap: 1.5rem;
    font-family: "Syne", sans-serif;
  }

  .contact-us-div-yshade {
    margin: 0.5rem;
    border: none;
    padding: 1rem;
    border-radius: 8px !important;
    color: white !important;
    background-color: #ae4e58;
    font-size: 1rem;
  }

  .skinshade-texts {
    font-size: 5.2vw;
    text-align: center;
    margin-bottom: 1rem;
  }
}

.contact-us-div-yshade a {
  color: white !important;
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .data-set-main-div {
    flex-wrap: wrap;
    margin-top: 2rem;
  }

  .data-set-texts-div {
    color: #775039;
    padding: 1rem;
  }
}
