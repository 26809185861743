:root {
  --VerydarkBrown: #816751;
}

html,
body {
  box-sizing: border-box !important;
}

.navbar-main {
  display: flex;
  justify-content: space-evenly;
  z-index: 99 !important;
  width: 100%;
  font-family: "Syne", sans-serif !important;
}

.nav-logo-img {
  width: 10vw;
}

.navbar-toggled-state {
  background-color: #eee1d4 !important;
}

.navbarcomponent {
  width: 100% !important;
  justify-content: space-between !important;
  background-color: white !important;
}

.custom-nav-input-search {
  border: none !important;
  outline: none;
  border-bottom: 1px solid white !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  color: white !important;
  font-size: 1.5rem !important;
}

.custom-nav-input-search-scrolled {
  border: none !important;
  outline: none;
  border-bottom: 1px solid var(--VerydarkBrown) !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  color: var(--VerydarkBrown) !important;
  font-size: 1.5rem !important;
}

.custom-nav-input-search::placeholder {
  color: white !important;
}

.custom-nav-input-search-scrolled::placeholder {
  color: var(--VerydarkBrown) !important;
}

.custom-nav-input-search:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.custom-nav-input-search-scrolled:focus {
  outline: none !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

.brand-name {
  font-family: "Syne", sans-serif;
  font-weight: 800;
  cursor: pointer;
  margin-left: 2.5rem;
  font-size: 1.5rem !important;
}

.dropdown-menu {
}
.nav-links {
  color: white !important;
  font-family: "Syne", sans-serif;
  font-weight: 500;
  margin-left: 2rem;
  padding: 0.5rem !important;
  text-align: center;
  min-width: 120px !important;
  cursor: pointer;
  font-size: 1.5rem !important;
  margin-right: 0.5rem !important;
  text-decoration: none;
}

.dropdown-toggle-scrolled {
  color: var(--VerydarkBrown) !important;
}

.nav-links-scrolled {
  color: var(--VerydarkBrown) !important;
  font-family: "Syne", sans-serif;
  font-weight: 500;
  margin-left: 2rem;
  padding: 0.5rem !important;
  text-align: center;
  min-width: 120px !important;
  cursor: pointer;
  font-size: 1.5rem !important;
  margin-right: 0.5rem !important;
  text-decoration: none;
}

.contactusnavlink {
  border: 1px solid white !important;
  margin-right: 2rem !important;
}

.contactusnavlinkscrolled {
  border: 1px solid var(--VerydarkBrown) !important;
  margin-right: 2rem !important;
}

.nav-links:hover {
  scale: 1.15 !important;
}

.nav-links-scrolled:hover {
  scale: 1.15 !important;
}

.mobile-toggle-nav-btn {
  border: none !important;
  background-color: #eee1d4 !important;
}

.mobile-toggle-nav-btn:focus {
  box-shadow: none !important;
  border: none !important;
}

#basic-nav-dropdown {
  color: white !important;
  font-family: "Syne", sans-serif;
  font-weight: 500;
  margin-left: 2rem;
  padding: 0.5rem !important;
  text-align: center;
  min-width: 120px !important;
  cursor: pointer;
  font-size: 1.5rem !important;
  margin-right: 0.5rem !important;
  text-decoration: none;
}

#basic-nav-dropdown-scrolled {
  color: var(--VerydarkBrown);
  font-family: "Syne", sans-serif;
  font-weight: 500;
  margin-left: 2rem;
  padding: 0.5rem !important;
  text-align: center;
  min-width: 120px !important;
  cursor: pointer;
  font-size: 1.5rem !important;
  margin-right: 0.5rem !important;
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .nav-links {
    font-size: 1.3rem !important;
  }

  .nav-logo-img {
    width: 20vw;
  }
  .brand-name {
    margin-left: 1rem;
  }

  .mobile-toggle-nav-btn {
    margin-right: 1rem;
  }

  .input-search-div {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .custom-nav-input-search {
    width: 80% !important;
    border-bottom: 1px solid var(--VerydarkBrown) !important;
  }

  .nav-links {
    color: var(--VerydarkBrown) !important;
  }

  .custom-nav-input-search::placeholder {
    color: var(--VerydarkBrown) !important;
  }

  .custom-nav-input-search {
    color: var(--VerydarkBrown) !important;
  }
}

@media screen and (max-width: 600px) {
  .nav-logo-img {
    width: 35vw;
  }
}

.dropdown {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.dropdown-menu {
  background-color: #eee1d4 !important;
  font-size: 1.5rem !important;
  width: 70%;
  text-align: center !important;
}
